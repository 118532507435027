import { z } from "zod";

export const carDetailsSectionSchema = z.object({
  country: z
    .string({
      required_error: "Enter the country",
    })
    .min(1, "Enter a valid country"),

  ownerId: z
    .string({
      required_error: "Enter the owner ID",
    })
    .min(1, "Enter a valid owner ID"),

  franchiseId: z
    .string({
      required_error: "Enter the franchise ID",
    })
    .min(1, "Enter a valid franchise ID"),

  makeId: z.coerce
    .string({
      required_error: "Enter the make ID",
    })
    .min(1, "Enter a valid make ID"),

  modelId: z.coerce
    .string({
      required_error: "Enter the model ID",
    })
    .min(1, "Enter a valid model ID"),

  year: z
    .string({
      required_error: "Enter the year",
    })
    .min(1, "Enter a valid year"),

  price: z
    .string({
      required_error: "Enter the price",
    })
    .min(1, "Enter a valid price"),

  bodyTypeId: z.coerce
    .string({
      required_error: "Enter the body type ID",
    })
    .min(1, "Enter a valid body type ID"),

  engineType: z
    .string({
      required_error: "Enter the engine type",
    })
    .min(1, "Enter a valid engine type"),

  vin: z
    .string({
      required_error: "Enter the VIN",
    })
    .min(1, "Enter a valid VIN")
    .regex(/^[A-Za-z0-9]{15,}$/, "Enter a valid VIN"),

  interiorColor: z
    .string({
      required_error: "Enter the interior color",
    })
    .min(1, "Enter a valid interior color"),

  exteriorColor: z
    .string({
      required_error: "Enter the exterior color",
    })
    .min(1, "Enter a valid exterior color"),

  fuelType: z
    .string({
      required_error: "Enter the fuel type",
    })
    .min(1, "Enter a valid fuel type"),

  mileage: z.coerce.number().optional().default(0),

  mileageUnit: z
    .string({
      required_error: "Enter the mileage unit",
    })
    .min(1, "Enter a valid mileage unit"),

  transmissionType: z
    .string({
      required_error: "Enter the transmission type",
    })
    .min(1, "Enter a valid transmission type"),

  carCondition: z
    .string({
      required_error: "Enter the car condition",
    })
    .min(1, "Enter a valid car condition"),

  ownershipStatus: z
    .string({
      required_error: "Enter the ownership status",
    })
    .min(1, "Enter a valid ownership status"),

  registrationStatus: z
    .string({
      required_error: "Enter the registration status",
    })
    .min(1, "Enter a valid registration status"),

  plateNumber: z.string().optional(),

  isUpgraded: z
    .string({
      required_error: "Specify if the car is upgraded",
    })
    .min(1, "Specify if the car is upgraded"),

  upgradedFrom: z.string().optional(),

  upgradedTo: z.string().optional(),

  engineCc: z.string({
    required_error: "Enter the engine CC",
  }),

  location: z
    .string({
      required_error: "Enter the location",
    })
    .min(1, "Enter a valid location"),

  vinImageUrl: z
    .string({
      required_error: "Enter the image URL",
    })
    .min(1, "Enter a valid image URL"),

  stateId: z.coerce
    .string({
      required_error: "Enter the state",
    })
    .min(1, "Enter a valid state"),

  cityId: z.coerce
    .string({
      required_error: "Enter the city",
    })
    .min(1, "Enter a valid city"),

  imageUrl: z
    .string({
      required_error: "Enter the image URL",
    })
    .min(1, "Enter a valid image URL"),
});

export const carDetailsSectionKESchema = z.object({
  franchiseId: z
    .string({
      required_error: "Enter the franchise ID",
    })
    .min(1, "Enter a valid franchise ID"),

  country: z
    .string({
      required_error: "Enter the country",
    })
    .min(1, "Enter a valid country"),

  ownerId: z
    .string({
      required_error: "Enter the owner ID",
    })
    .min(1, "Enter a valid owner ID"),

  price: z
    .string({
      required_error: "Enter the price",
    })
    .min(1, "Enter a valid price"),

  makeId: z.coerce
    .string({
      required_error: "Enter the make ID",
    })
    .min(1, "Enter a valid make ID"),

  year: z
    .string({
      required_error: "Enter the year",
    })
    .min(1, "Enter a valid year"),

  modelId: z.coerce
    .string({
      required_error: "Enter the model ID",
    })
    .min(1, "Enter a valid model ID"),

  engineType: z
    .string({
      required_error: "Enter the engine type",
    })
    .min(1, "Enter a valid engine type"),

  bodyTypeId: z.coerce
    .string({
      required_error: "Enter the body type ID",
    })
    .min(1, "Enter a valid body type ID"),

  vin: z
    .string({
      required_error: "Enter the VIN",
    })
    .min(1, "Enter a valid VIN")
    .regex(/^[A-Za-z0-9]{15,}$/, "Enter a valid VIN"),

  interiorColor: z
    .string({
      required_error: "Enter the interior color",
    })
    .min(1, "Enter a valid interior color"),

  fuelType: z
    .string({
      required_error: "Enter the fuel type",
    })
    .min(1, "Enter a valid fuel type"),

  mileage: z.coerce.number().optional().default(0),

  exteriorColor: z
    .string({
      required_error: "Enter the exterior color",
    })
    .min(1, "Enter a valid exterior color"),

  transmissionType: z
    .string({
      required_error: "Enter the transmission type",
    })
    .min(1, "Enter a valid transmission type"),

  mileageUnit: z
    .string({
      required_error: "Enter the mileage unit",
    })
    .min(1, "Enter a valid mileage unit"),

  carCondition: z
    .string({
      required_error: "Enter the car condition",
    })
    .min(1, "Enter a valid car condition"),

  plateNumber: z.string().optional(),

  registrationStatus: z
    .string({
      required_error: "Enter the registration status",
    })
    .min(1, "Enter a valid registration status"),

  upgradedFrom: z.string().optional(),

  isUpgraded: z.string({
    required_error: "Specify if the car is upgraded",
  }),

  upgradedTo: z.string().optional(),

  engineCc: z.string({
    required_error: "Enter the engine CC",
  }),

  engineDisplacement: z.string().min(1, "Enter a valid engine displacement"),

  vinImageUrl: z
    .string({
      required_error: "Enter the image URL",
    })
    .min(1, "Enter a valid image URL"),

  ownershipStatus: z
    .string({
      required_error: "Enter the ownership status",
    })
    .min(1, "Enter a valid ownership status"),

  registrationSeries: z.string().min(1, "Enter a valid registration series"),

  imageUrl: z
    .string({
      required_error: "Enter the image URL",
    })
    .min(1, "Enter a valid image URL"),

  stateId: z.coerce
    .string({
      required_error: "Enter the state",
    })
    .min(1, "Enter a valid state"),

  location: z
    .string({
      required_error: "Enter the location",
    })
    .min(1, "Enter a valid location"),

  cityId: z.coerce
    .string({
      required_error: "Enter the city",
    })
    .min(1, "Enter a valid city"),
});

export const carDetailsSectionOthersSchema = z.object({
  imageUrl: z
    .string({
      required_error: "Enter the image URL",
    })
    .min(1, "Enter a valid image URL"),

  mileageUnit: z
    .string({
      required_error: "Enter the mileage unit",
    })
    .min(1, "Enter a valid mileage unit"),

  upgradedFrom: z.string().optional(),

  vinImageUrl: z
    .string({
      required_error: "Enter the image URL",
    })
    .min(1, "Enter a valid image URL"),

  carCondition: z
    .string({
      required_error: "Enter the car condition",
    })
    .min(1, "Enter a valid car condition"),

  transmissionType: z
    .string({
      required_error: "Enter the transmission type",
    })
    .min(1, "Enter a valid transmission type"),

  ownershipStatus: z
    .string({
      required_error: "Enter the ownership status",
    })
    .min(1, "Enter a valid ownership status"),

  isUpgraded: z.string({
    required_error: "Specify if the car is upgraded",
  }),

  registrationStatus: z
    .string({
      required_error: "Enter the registration status",
    })
    .min(1, "Enter a valid registration status"),

  plateNumber: z.string().optional(),

  upgradedTo: z.string().optional(),

  engineCc: z.string({
    required_error: "Enter the engine CC",
  }),

  location: z
    .string({
      required_error: "Enter the location",
    })
    .min(1, "Enter a valid location"),

  country: z
    .string({
      required_error: "Enter the country",
    })
    .min(1, "Enter a valid country"),

  cityId: z.coerce
    .string({
      required_error: "Enter the city",
    })
    .min(1, "Enter a valid city"),

  price: z
    .string({
      required_error: "Enter the price",
    })
    .min(1, "Enter a valid price"),

  ownerId: z
    .string({
      required_error: "Enter the owner ID",
    })
    .min(1, "Enter a valid owner ID"),

  stateId: z.coerce
    .string({
      required_error: "Enter the state",
    })
    .min(1, "Enter a valid state"),

  franchiseId: z
    .string({
      required_error: "Enter the franchise ID",
    })
    .min(1, "Enter a valid franchise ID"),

  makeId: z.coerce
    .string({
      required_error: "Enter the make ID",
    })
    .min(1, "Enter a valid make ID"),

  exteriorColor: z
    .string({
      required_error: "Enter the exterior color",
    })
    .min(1, "Enter a valid exterior color"),

  modelId: z.coerce
    .string({
      required_error: "Enter the model ID",
    })
    .min(1, "Enter a valid model ID"),

  year: z
    .string({
      required_error: "Enter the year",
    })
    .min(1, "Enter a valid year"),

  vin: z
    .string({
      required_error: "Enter the VIN",
    })
    .min(1, "Enter a valid VIN")
    .regex(/^[A-Za-z0-9]{15,}$/, "Enter a valid VIN"),

  bodyTypeId: z.coerce
    .string({
      required_error: "Enter the body type ID",
    })
    .min(1, "Enter a valid body type ID"),

  engineType: z
    .string({
      required_error: "Enter the engine type",
    })
    .min(1, "Enter a valid engine type"),

  interiorColor: z
    .string({
      required_error: "Enter the interior color",
    })
    .min(1, "Enter a valid interior color"),

  fuelType: z
    .string({
      required_error: "Enter the fuel type",
    })
    .min(1, "Enter a valid fuel type"),

  mileage: z.coerce.number().optional().default(0),
});

// const snapshotNames = [
//   "leftFront",
//   "rightFront",
//   "front",
//   "back",
//   "leftBack",
//   "rightBack",
//   "interiorFront",
//   "interiorBack",
// ] as const;

export const carSnapshotsSectionSchema = z.object({
  carId: z.string().min(1),
  snapshots: z
    .array(
      z.object({
        // name: z.enum(snapshotNames),
        name: z.string(),
        type: z.string(),
        url: z.string().url(),
      })
    )
    .optional(),
});

// const documentNames = [
//   "vehicleInsurance",
//   "vehicleCustoms",
//   "vehicleDuty",
//   "vehicleLicense",
// ] as const;

export const carDocumentsSectionSchema = z.object({
  carId: z.string().min(1),
  documents: z
    .array(
      z.object({
        // name: z.enum(documentNames),
        name: z.string(),
        type: z.string(),
        url: z.string().url(),
      })
    )
    .optional(),
});

export const carFeaturesSectionSchema = z.object({
  carId: z.string().min(1),
  features: z.array(z.string()),
});

export const carSummarySectionSchema = z.object({
  carId: z.string().min(1),
  summary: z.string(),
});

export const createCarFormSchema = z.object({
  makeId: z.coerce
    .string({
      required_error: "Enter the make ID",
    })
    .min(1, "Enter a valid make ID"),

  ownerId: z
    .string({
      required_error: "Enter the owner ID",
    })
    .min(1, "Enter a valid owner ID"),

  country: z
    .string({
      required_error: "Enter the country",
    })
    .min(1, "Enter a valid country"),

  franchiseId: z
    .string({
      required_error: "Enter the franchise ID",
    })
    .min(1, "Enter a valid franchise ID"),

  modelId: z.coerce
    .string({
      required_error: "Enter the model ID",
    })
    .min(1, "Enter a valid model ID"),

  year: z
    .string({
      required_error: "Enter the year",
    })
    .min(1, "Enter a valid year"),

  price: z
    .string({
      required_error: "Enter the price",
    })
    .min(1, "Enter a valid price"),

  bodyTypeId: z.coerce
    .string({
      required_error: "Enter the body type ID",
    })
    .min(1, "Enter a valid body type ID"),

  vin: z
    .string({
      required_error: "Enter the VIN",
    })
    .min(1, "Enter a valid VIN")
    .regex(/^[A-Za-z0-9]{15,}$/, "Enter a valid VIN"),

  interiorColor: z
    .string({
      required_error: "Enter the interior color",
    })
    .min(1, "Enter a valid interior color"),

  mileage: z.coerce.number().optional().default(0),

  exteriorColor: z
    .string({
      required_error: "Enter the external color",
    })
    .min(1, "Enter a valid external color"),

  fuelType: z
    .string({
      required_error: "Enter the fuel type",
    })
    .min(1, "Enter a valid fuel type"),

  upgradedTo: z.string().optional(),

  mileageUnit: z
    .string({
      required_error: "Enter the mileage unit",
    })
    .min(1, "Enter a valid mileage unit"),

  transmissionType: z
    .string({
      required_error: "Enter the transmission type",
    })
    .min(1, "Enter a valid transmission type"),

  carCondition: z
    .string({
      required_error: "Enter the car condition",
    })
    .min(1, "Enter a valid car condition"),

  registrationStatus: z
    .string({
      required_error: "Enter the registration status",
    })
    .min(1, "Enter a valid registration status"),

  engineDisplacement: z.string().optional(),

  isUpgraded: z
    .string({
      required_error: "Specify if the car is upgraded",
    })
    .min(1, "Specify if the car is upgraded"),

  engineCc: z.string({
    required_error: "Enter the engine CC",
  }),

  plateNumber: z.string().optional(),

  registrationSeries: z.string().optional(),

  upgradedFrom: z.string().optional(),

  location: z
    .string({
      required_error: "Enter the location",
    })
    .min(1, "Enter a valid location"),

  stateId: z.coerce
    .string({
      required_error: "Enter the state",
    })
    .min(1, "Enter a valid state"),

  ownershipStatus: z
    .string({
      required_error: "Enter the ownership status",
    })
    .min(1, "Enter a valid ownership status"),

  cityId: z.coerce
    .string({
      required_error: "Enter the city",
    })
    .min(1, "Enter a valid city"),

  imageUrl: z
    .string({
      required_error: "Enter the image URL",
    })
    .min(1, "Enter a valid image URL"),

  vinImageUrl: z
    .string({
      required_error: "Enter the image URL",
    })
    .min(1, "Enter a valid image URL"),

  createdBy: z
    .string({
      required_error: "Created by is required",
    })
    .min(1, "Created by is required"),

  engineType: z
    .string({
      required_error: "Enter the engine type",
    })
    .min(1, "Enter a valid engine type"),
});

export const createCarDefaultValues: CreateCarFormValues = {
  formId: "",
  country: "",
  isDealer: "",
  ownerId: "",
  carId: "",
  franchise: "",
  franchiseId: "",
  make: "",
  makeId: "",
  model: "",
  modelId: "",
  year: "",
  price: "",
  bodyType: "",
  bodyTypeId: "",
  engineType: "",
  vin: "",
  interiorColor: "",
  exteriorColor: "",
  fuelType: "",
  mileage: "",
  mileageUnit: "",
  transmissionType: "",
  carCondition: "",
  ownershipStatus: "",
  registrationStatus: "",
  plateNumber: "",
  isUpgraded: "",
  upgradedFrom: "",
  upgradedTo: "",
  engineCc: "",
  engineDisplacement: "",
  registrationSeries: "",
  location: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  imageUrl: "",
  snapshots: [],
  documents: [],
  searchFeatures: "",
  vinLookup: "",
  trim: "",
  trimId: "",
  features: [],
  summary: "",
  createdBy: "",
  searchFranchise: "",
  isModelDecoded: "",
  vinImage: "",
  vinImageUrl: "",
};

export type FilePayload = {
  name: string;
  label?: string;
  url: string;
  type: string;
};

export interface CreateCarFormValues {
  formId: string;
  country: string;
  isDealer: string;
  ownerId: string;
  carId: string;
  franchise: string;
  franchiseId: string;
  make: string;
  makeId: string;
  model: string;
  modelId: string;
  year: string;
  price: string;
  bodyType: string;
  bodyTypeId: string;
  engineType: string;
  vin: string;
  interiorColor: string;
  exteriorColor: string;
  fuelType: string;
  mileage: string;
  mileageUnit: string;
  transmissionType: string;
  carCondition: string;
  ownershipStatus: string;
  registrationStatus: string;
  plateNumber: string;
  isUpgraded: string;
  upgradedFrom: string;
  upgradedTo: string;
  engineCc: string;
  engineDisplacement: string;
  registrationSeries: string;
  location: string;
  state: string;
  stateId: string;
  city: string;
  cityId: string;
  imageUrl: string;
  snapshots: FilePayload[];
  documents: FilePayload[];
  searchFeatures: string;
  vinLookup: string;
  trim: string;
  trimId: string;
  features: any[];
  summary: string;
  createdBy: string;
  searchFranchise: string;
  isModelDecoded: string;
  vinImage: string;
  vinImageUrl: string;
}
